<script setup lang="ts">
import { useRouter } from "vue-router";
import type { Bill } from "@/interfaces/bill.interface";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";
import { ChevronRightIcon } from "@heroicons/vue/solid";

const PAID_STATUS_ID = 3;

const props = defineProps<{
  bill: Bill;
}>();

const router = useRouter();

const goToBillDetail = () => {
  router.push({
    name: "BillDetail",
    params: { id: props.bill.id },
  });
};
</script>
<template>
  <div
    class="flex justify-between items-center border-[1.5px] rounded-lg border-neutral-300 p-4 cursor-pointer hover:bg-grayDetails-300"
    @click="goToBillDetail"
  >
    <div class="flex gap-3">
      <ExclamationCircleIcon
        v-if="bill.estado !== PAID_STATUS_ID"
        class="text-link w-6"
      />
      <CheckCircleIcon v-else class="text-greenStatus-200 w-6" />
      <div>
        <p class="text-sm md:text-base font-semibold text-grayDetails-400">
          Factura {{ bill.nro_factura }}
        </p>
        <p class="text-sm md:text-base font-medium text-grayDetails-400 mb-1">
          $ {{ bill.monto_factura }}
        </p>
        <p class="text-xs md:text-sm font-medium text-grayDetails-600">
          Póliza N° {{ bill.nro_poliza }}
          <span v-if="bill.poliza?.alias"> ({{ bill.poliza.alias }}) </span>
        </p>
      </div>
    </div>
    <ChevronRightIcon class="w-6 text-grayDetails-600" />
  </div>
</template>

<style scoped></style>
