import type { BillDetail } from "@/interfaces/bill.interface";
import { axiosInstance as axios } from "./axios.config";

export const fetchBills = async (): Promise<{ data: any }> => {
  return axios.get("/invoice_list/");
};

export const fetchBillDetail = async (
  id: number
): Promise<{ data: BillDetail }> => {
  return axios.get(`/invoice_detail/${id}/`);
};

export const creatNewBillRequest = async (billRequest: any) => {
  // return await axios.post(`/pagotic/`, billRequest);
  return console.log("billRequest", billRequest);
};
