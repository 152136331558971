<script setup lang="ts">
import { useRouter } from "vue-router";
import type { BillDetail } from "@/interfaces/bill.interface";
import { ref, watch } from "vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import Modal from "@/components/Modal.vue";
import { useClientStore } from "@/stores/clientStore";
import { EyeIcon } from "@heroicons/vue/outline";
import { creatNewBillRequest, fetchBillDetail } from "@/services/bill.services";

const props = defineProps({
  id: String,
});

const router = useRouter();
const clientStore = useClientStore();

const clientTheme = clientStore.theme;

const bill = ref<BillDetail | null>();
const paymentModalIsOpen = ref(false);

watch(
  () => props.id,
  (newId) => {
    if (newId) {
      loadBillDetail(Number(newId));
    }
  },
  {
    immediate: true,
  }
);

async function loadBillDetail(id: number) {
  try {
    const response = await fetchBillDetail(id);
    bill.value = response.data;
  } catch (error) {
    console.error("Error fetching bill detail:", error);
  }
}

const openInvoicePdf = () => {
  if (!bill.value?.archivo_factura) return;
  const pdfUrl = bill.value.archivo_factura;
  window.open(pdfUrl, "_blank");
};

function openPaymentModal() {
  paymentModalIsOpen.value = true;
}

function closePaymentModal() {
  paymentModalIsOpen.value = false;
}

async function sendPayment(billRequest: any) {
  try {
    await creatNewBillRequest(billRequest);
  } catch (error) {
    console.log(error);
  } finally {
    paymentModalIsOpen.value = false;
  }
}
</script>
<template>
  <div
    class="min-h-full flex-col flex-grow justify-start py-12 px-5 md:px-24 gap-4"
    :class="clientTheme"
  >
    <header class="mb-5 md:mb-8 mt-1">
      <button
        @click="router.push({ name: 'Bills' })"
        class="flex btn-transition items-center text-grayDetails-100"
      >
        <ChevronLeftIcon class="mr-1 text-grayDetails-100" />
        <span class="text-primary font-montserrat text-skin-major"
          >Factura {{ bill?.nro_factura }}</span
        >
      </button>
    </header>
    <main v-if="bill" class="flex flex-col gap-y-2 md:gap-y-4">
      <div>
        <h5 class="title text-[15px] md:text-lg mb-0">
          Factura {{ bill.nro_factura }}
        </h5>
      </div>
      <div
        class="flex flex-col border-[1.5px] rounded-lg border-neutral-300 p-4 md:p-6 gap-4 md:gap-10 mb-4 md:mb-1"
      >
        <div class="flex flex-col md:grid md:grid-cols-3 gap-4">
          <div>
            <p class="text-sm md:text-base font-semibold text-grayDetails-400">
              Producto
            </p>
            <p class="text-xs md:text-sm font-medium text-grayDetails-600">
              {{ bill.poliza.product_title }}
            </p>
          </div>
          <div>
            <p class="text-sm md:text-base font-semibold text-grayDetails-400">
              N° de póliza
            </p>
            <p class="text-xs md:text-sm font-medium text-grayDetails-600">
              {{ bill.nro_poliza }}
              <span v-if="bill.poliza.alias">({{ bill.poliza.alias }})</span>
            </p>
          </div>
          <div>
            <p class="text-sm md:text-base font-semibold text-grayDetails-400">
              Monto
            </p>
            <p class="text-xs md:text-sm font-medium text-grayDetails-600">
              $ {{ bill.monto_factura }}
            </p>
          </div>
        </div>
        <div class="flex flex-col md:grid md:grid-cols-3 gap-4">
          <div>
            <p class="text-sm md:text-base font-semibold text-grayDetails-400">
              Estado
            </p>
            <p
              class="text-xs md:text-sm font-semibold"
              :class="bill.estado !== 3 ? 'text-link' : 'text-greenStatus-200'"
            >
              {{ bill.descripcion_estado }}
            </p>
          </div>
          <div>
            <p class="text-sm md:text-base font-semibold text-grayDetails-400">
              Fecha de emisión
            </p>
            <p class="text-xs md:text-sm font-medium text-grayDetails-600">
              {{ bill.fecha_factura }}
            </p>
          </div>
          <div>
            <p class="text-sm md:text-base font-semibold text-grayDetails-400">
              Fecha de vencimiento
            </p>
            <p class="text-xs md:text-sm font-medium text-grayDetails-600">
              {{ bill.fecha_pago }}
            </p>
          </div>
        </div>
        <div>
          <p class="text-sm md:text-base font-semibold text-grayDetails-400">
            Método de pago
          </p>
          <p class="text-xs md:text-sm font-medium text-grayDetails-600">
            {{ bill.poliza.media_payment }}
          </p>
          <!-- <p class="text-xs md:text-sm font-medium text-grayDetails-600">
            {{ bill.poliza }}
          </p> -->
        </div>
      </div>
      <div class="flex flex-col justify-end md:flex-row gap-2 md:gap-5">
        <button
          @click="openInvoicePdf"
          class="flex items-center justify-center border-[1px] rounded-lg border-skin-major p-3 md:py-[11px] md:px-10 gap-1 mb-[2px] cursor-pointer"
        >
          <EyeIcon class="w-4 text-skin-major" />
          <p class="text-[15px] text-skin-major font-bold">Ver factura</p>
        </button>
        <button
          v-if="bill.estado === 2"
          class="flex items-center justify-center rounded-lg bg-skin-major p-3 md:py-[11px] md:px-12 cursor-pointer"
          @click="openPaymentModal"
        >
          <p class="text-[15px] font-bold text-white">Pagar ahora</p>
        </button>
      </div>
    </main>

    <p v-else>No se encontró la factura.</p>
  </div>
  <Teleport to="body">
    <Modal
      :isOpen="paymentModalIsOpen"
      v-if="paymentModalIsOpen"
      @closeModal="closePaymentModal"
    >
      <template #body>
        <div class="max-h-[65vh] md:max-h-[82vh] overflow-y-auto">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis
          perspiciatis incidunt labore quisquam consequatur nisi, ullam Lorem
          ipsum, dolor sit amet consectetur adipisicing elit. Illum aspernatur
          totam harum nobis reiciendis quasi provident, quisquam ex explicabo
          mollitia quo, debitis dolor doloremque! Impedit aliquid a officiis
          dolores blanditiis. Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Eligendi facere modi adipisci hic laborum. Saepe
          sequi distinctio ex dolorem! Quod impedit earum delectus perspiciatis
          ducimus rem fugiat. Libero, sunt corrupti. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Aliquid rerum ratione excepturi est
          voluptatibus iste a laborum odit asperiores natus vitae tempora fugit
          quaerat quo temporibus obcaecati odio, numquam nesciunt? Lorem ipsum
          dolor, sit amet consectetur adipisicing elit. Facilis perspiciatis
          incidunt labore quisquam consequatur nisi, ullam Lorem ipsum, dolor
          sit amet consectetur adipisicing elit. Illum aspernatur totam harum
        </div>
      </template>
      <template #footer>
        <div
          class="flex flex-col justify-end md:flex-row gap-2 md:gap-5 w-full md:w-auto"
        >
          <button
            class="flex items-center justify-center border-[1px] rounded-lg border-skin-major p-3 md:py-[11px] md:px-10 gap-1 mb-[2px] cursor-pointer"
            @click="closePaymentModal"
          >
            <p class="text-[15px] text-skin-major font-bold">Cerrar</p>
          </button>
          <button
            class="flex items-center justify-center rounded-lg bg-skin-major p-3 md:py-[11px] md:px-12 cursor-pointer"
            @click="sendPayment(bill?.nro_factura)"
          >
            <p class="text-[15px] font-bold text-white">Pagar</p>
          </button>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<style scoped></style>
